import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';

export const createBooking = async (slotId, celebId, paymentResponse, orderId, captureId, status, invitations_id) => {
    try {
        let ObjToSend = {
            slot_id: slotId,
            celebrity_id: parseInt(celebId),
            payment_response: paymentResponse,
            paypal_order_id: orderId,
            paypal_capture_id: captureId,
            paypal_payment_status: status,
        };

        if (invitations_id !== "" || invitations_id !== undefined){
            ObjToSend.invitation_id = invitations_id;
        }
        
        let response = await apiCall(APIUrlList.CREATEBOOKING, ObjToSend, 'POST', true);

        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }

        return response.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const buyExclusivePost = async (fabId, start_time, end_time, price, paymentResponse, orderId, captureId, status) => {
    try {
        let ObjToSend = {
            fab_id: fabId,
            start_time: start_time,
            end_time: end_time,
            payment_amount: price,
            payment_response: paymentResponse,
            paypal_order_id: orderId,
            paypal_capture_id: captureId,
            paypal_payment_status: status,
        };
            
        let response = await apiCall(APIUrlList.ADDEXCLUSIVEPOST, ObjToSend, 'POST', true);
            
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }

        return response.data;

    } catch (error){
        _sendResponse('error', error);
    }
};


export const addOrder = async (amount, receiptId, paymentResponse, charge, orderId, captureId, status) => {
    try {
        let ObjToSend = {
            amount: amount,
            payment_receipt_id: receiptId,
            payment_response: paymentResponse,
            shipping_charge: charge,
            paypal_order_id: orderId,
            paypal_capture_id:captureId,
            paypal_payment_status: status
        };

        let response = await apiCall(APIUrlList.ADDORDER, ObjToSend, 'POST', true);

        return response.data.data;
    } catch (error){
        _sendResponse("error", error);
    }
};


export const addOrderItems = async (cartIds, orderId) => {

    try {
        let ObjToSend = {
            cart_id: cartIds,
            order_id: orderId,
        };
        let response = await apiCall(APIUrlList.ADDORDERITEMS, ObjToSend, 'POST', true);
        
        if (response.data.status === true){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data;
    } catch (error){
        _sendResponse("error", error);
    }
};