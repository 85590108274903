<template>
  <b-container>
    <LoadingSpinner v-if="loading" />
    <b-row class="mt-5">
      <b-col
        xl="12"
        lg="12"
      >
        <b-row class="step1-header">
          <b-col class="text-right">
            <img
              :src="profilePic"
              :class="style['profile-image']"
              @error="setPlaceholderProfileImg"
            >
          </b-col>
          <b-col class="font-weight-bold h3 mt-3">
            <p class="mb-0">
              {{ type === 'content' ? `${fabName}` : `${call_duration}` }}
            </p>
            <p>{{ type === 'content' ? "exclusive content access" : `with ${fabName}` }}</p>
          </b-col>
        </b-row>
        <b-row
          cols="2"
          cols-lg="3"
          :class="['mt-5 mb-5', style['table-wrapper']]"
        >
          <b-col>
            <div class="d-flex flex-column mr-2">
              <div class="text-uppercase font-weight-bold border-bottom pb-2">
                {{ type === 'content' ? 'Start Date' : 'Date' }}
              </div>
              <div class="font-weight-light">
                <p class="mb-0">
                  {{ day }},
                </p>
                <p> {{ date }} </p>
              </div>
            </div>
          </b-col>
          <b-col v-if="type ==='video'">
            <div class="d-flex flex-column mr-2">
              <div class="text-uppercase font-weight-bold border-bottom pb-2">
                Time
              </div>
              <div class="font-weight-light">
                {{ time }} {{ timezone }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex flex-column mr-2">
              <div 
                :class="[type === 'content' ? 'text-left' : 'text-right', 'text-uppercase font-weight-bold border-bottom pb-2']"
              >
                {{ type === 'content' ? 'Cost Per Month' : 'Total Cost' }}
              </div>
              <div :class="[type === 'content' ? 'text-left' : 'text-right', 'font-weight-bold']">
                ${{ parseFloat(price).toFixed(2) }}
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="text-center mb-5 mt-4">
          <b-button
            class="px-3 text-uppercase"
            pill
            variant="primary"
            @click="handlePayment"
          >
            Proceed to payment
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import style from '../../styles/bookings.module.scss';
// import { buyExclusivePost } from '../../api/bookings';
import LoadingSpinner from '../Common/LoadingSpinner';
import moment from 'moment-timezone';
import { _sendResponse, setPlaceholderProfileImg } from '../../js/common-function';
export default {
    name:'Step1',
    components:{ LoadingSpinner },
    props:{
        next:{
            type: Function,
            default:() => {}
        },
        type:{
            type:String,
            default:""
        },
        slotData:{
            type:[Object, Array],
            default:()=>{}
        },
        slots:{
            type:Object,
            default:()=>{},
        },
    },
    data(){
        return {
            style,
            profilePic: localStorage.getItem('fabProfilePic'),
            duration: "",
            fabName: localStorage.getItem('fabName'),
            day: "",
            date:"",
            time:"",
            price: 0,
            slotId:"",
            startTime: "",
            endTime: "",
            fabId: localStorage.getItem('fabId'),
            loading: false,
            timezone: '',
            callType:'video call',
            call_duration: '',
            setPlaceholderProfileImg,
            meetingEndTime: "",
        };
    },
    watch:{
        slots:function(newVal){
            var minutes = Math.floor(newVal.time_interval / 60);
            if (newVal.call_type == 2){
                this.callType = "live streaming";
            }
            this.call_duration = minutes+"-minutes "+this.callType;
            if (minutes >= 60){
                var hours = Math.floor(minutes / 60);  
                var mins = minutes % 60;
                this.call_duration = hours+"-hour "+this.callType;
                if (mins > 0){
                    this.call_duration = hours + "hours " + mins+"minutes "+this.callType;
                }
            }
            this.slotId = newVal.id;
            this.price = isNaN(newVal.price) ? 0 : newVal.price;
            this.timezone = newVal.time_zone;
            let start = new Date(newVal.start_time.replace(/-/g, '/')); //+' UTC'
            start = start.toString(); 
            let end = new Date(newVal.end_time.replace(/-/g, '/')); //+' UTC'
            this.day = moment(newVal.start_time).format('dddd');
            this.date = moment(newVal.start_time).format('MMMM DD, YYYY');
            let start_time = moment(start).format('h:mm a');
            let end_time = moment(end).format('h:mm a');
            this.time = start_time + ' - ' + end_time;
            this.meetingEndTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    created(){
        if (this.type === "video"){
            if (Object.keys(this.slots).length > 0){
                var minutes = Math.floor(this.slots.time_interval / 60); 
                if (this.slots.call_type == 2){
                    this.callType = "live streaming";
                }
                this.slotId = this.slots.id;
                if (minutes >= 60){
                    var hours = Math.floor(minutes / 60);  
                    var mins = minutes % 60;
                    this.call_duration = hours+"-hour "+this.callType;
                    if (mins > 0){
                        this.call_duration = hours + "hours " + mins+"minutes "+this.callType;
                    }
                }
                this.price = isNaN(this.slots.price) ? 0 : this.slots.price;
                this.timezone = this.slots.time_zone;
                let start = new Date(this.slots.start_time.replace(/-/g, '/')); //+' UTC'
                let end = new Date(this.slots.end_time.replace(/-/g, '/')); //+' UTC'
                start = start.toString();

                this.day = moment(start).format('dddd');
                this.date = moment(start).format('MMMM DD, YYYY');
                let start_time = moment(start).format('h:mm a');
                let end_time = moment(end).format('h:mm a');
                this.time = start_time + ' - ' + end_time;
            }
        } else if (this.type === "content"){
            this.day = "Today";
            let date = new Date();
            let endDate = new Date(date);
            endDate.setMonth(endDate.getMonth()+1);
            this.date = moment(date).format('MMMM DD, HH:MM');
            this.startTime = moment(date).format('YYYY-MM-DD HH:MM:SS');
            this.endTime = moment(endDate).format('YYYY-MM-DD HH:MM:SS');
            this.price = isNaN(localStorage.getItem('fabPostFees')) ? 0 : localStorage.getItem('fabPostFees');
        }
    },
    methods:{
        async handlePayment(){
            if (this.type === "video"){
                if (this.price === 0 || this.price === '0.00'){
                    _sendResponse('error', 'Payment not required as the amount is 0.00'); //Something went wrong, please try again later
                    this.$router.push('/meetings');
                } 
                else if (this.meetingEndTime <= moment(new Date()).format('YYYY-MM-DD HH:mm:ss')){
                    _sendResponse('error', 'Meeting has expired');
                    this.$router.push('/meetings');
                }
                else {
                    this.next('1');
                }
                this.loading = false;
            } else if (this.type === "content") {
                // console.log(this.price, 'price');
                if (this.price == 0 || this.price === '0.00'){
                    _sendResponse('error', 'Payment not required as the amount is 0.00');
                } else {
                    this.next('1');
                }
                this.loading = false;
            }
        }
    }
};
</script>