import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';
import axios from 'axios';

export const cartProductList = async () => {
    try {
        const response = await apiCall(APIUrlList.CARTPRODUCTLIST, null, 'GET', true);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const addToCart = async (id, price) => {
    try {
        let objToSend = {
            product_id: id,
            price: price
        };
        const response = await apiCall(APIUrlList.ADDTOCART, objToSend, 'POST', true);
        if (response.data.status){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const removeFromCart = async (id) => {
    try {
        let objToSend = {
            cart_id: id
        };
        const response = await apiCall(APIUrlList.REMOVEFROMCART, objToSend, 'POST', true);
        if (response.data.status){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const getProductDetails = async (id) => {
    try {
        let response = await apiCall(APIUrlList.GETPRODUCTDETAILS + `?product_id=${id}`, null, 'GET', true);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const getRemainingProducts = async (fabId, currentId) => {
    try {
        let objToSend = {
            fab_id: fabId,
            ignore_product_id:currentId
        };
        let response = await apiCall(APIUrlList.PRODUCTLIST, objToSend, 'POST', true);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};



export const createOrderApi = async (amount) => {
    try {
        let response = await apiCall('/payments/createOrder', { amount: amount }, 'POST', true);
        return response.data.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const captureOrder = async (id) => {
    try {
        let response = await apiCall('/payments/captureOrder', { order_id: id }, 'POST', true);
        return response.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};


export const captureDemo = async (orderid) => {
    try {
        let paypalToken = "A21AALcy9shskOdXlNoRdMrqgCVBQK0mnI3zVqSueJvZE0IliOlwAe9jKGUKh_EAB4bmSFanWmONZXJjlT6IFFN3p8MosNW0g";
        let url = `https://api.sandbox.paypal.com/v2/checkout/orders/${orderid}/capture`;
        let response = await axios.post(url,{}, {headers: {Authorization: 'Bearer '+paypalToken}});
        return response;
    } catch (error){
        _sendResponse('error', error);
    }
};