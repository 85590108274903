<template>
  <b-container class="mt-5">
    <LoadingSpinner v-if="loading" />
    <b-row :class="[style['detail-wrapper'], 'py-3']">
      <b-col lg="1">
        <img
          :src="profilePic"
          :class="style['step2-image']"
          @error="setPlaceholderProfileImg"
        >
      </b-col>
      <b-col
        class="ml-3"
        lg="9"
      >
        <p class="font-weight-bold mb-0 h5">
          {{ type === 'content' ? `${fabName}'s exclusive content access` : `${call_duration} with ${fabName}` }}
        </p>
        <p class="font-weight-light">
          {{ type === 'content' ? `Start date: ${day}, ${date}` : `${day}, ${date} ${time} ${timezone}` }}
        </p>
      </b-col>
      <b-col class="font-weight-bold h4 mt-3">
        ${{ parseFloat(price).toFixed(2) }}
      </b-col>
    </b-row>
    <div
      ref="paypal"
      class="mt-3"
      style="margin-left: 25%;width: 50%;text-align: center;justify-content: center;"
    />
  </b-container>
</template>

<script>
import style from '../../styles/bookings.module.scss';
import paypal from '../../assets/images/paypall.png';
import { createOrderApi, captureOrder } from '../../api/shopping';
import { createBooking, buyExclusivePost } from '../../api/bookings';
// import { rejectInvitation } from '../../api/meeting';
import moment from 'moment-timezone';
import LoadingSpinner from '../Common/LoadingSpinner.vue';
import { setPlaceholderProfileImg } from "../../js/common-function";
import {PAYPAL_CLIENT_ID} from "../../config/config";

export default {
    name: 'Step2',
    components:{ LoadingSpinner },
    props:{
        next:{
            type: Function,
            default:() => {}
        },
        type:{
            type:String,
            default:""
        },
        slotData:{
            type:[Object, Array],
            default:()=>{}
        },
        slots:{
            type:Object,
            default:()=>{},
        },
        inviteId:{
            type:String,
            default: "",
        }
    },
    data(){
        return {
            style,
            profilePic: localStorage.getItem('fabProfilePic'),
            paymentMethod:"paypal",
            paypal,
            fabName: localStorage.getItem('fabName'),
            day: "",
            date:"",
            time:"",
            price: "",
            slotId:"",
            startTime: "",
            endTime: "",
            fabId: localStorage.getItem('fabId'),
            loading: false,
            timezone: '',
            callType:'video call',
            call_duration: '',
            invite_id: this.inviteId,
            setPlaceholderProfileImg
        };
    },
    watch:{
        inviteId:function(newVal){
            this.invite_id = newVal;
        }
    },
    created(){
        if (this.type === "video"){
            if (Object.keys(this.slots).length > 0){
                var minutes = Math.floor(this.slots.time_interval / 60);
                if (this.slots.call_type == 2){
                    this.callType = "live streaming";
                }
                this.slotId = this.slots.id;
                if (minutes >= 60){
                    var hours = Math.floor(minutes / 60);
                    var mins = minutes % 60;
                    this.call_duration = hours+"-hour "+this.callType;
                    if (mins > 0){
                        this.call_duration = hours + "hours " + mins+"minutes "+this.callType;
                    }
                }
                this.price = isNaN(this.slots.price) ? 0 : this.slots.price;
                this.timezone = this.slots.time_zone;
                let start = new Date(this.slots.start_time.replace(/-/g, '/')); //+' UTC'
                let end = new Date(this.slots.end_time.replace(/-/g, '/')); //+' UTC'
                this.day = moment(start).format('dddd');
                this.date = moment(start).format('MMMM DD, YYYY');
                let start_time = moment(start).format('h:mm a');
                let end_time = moment(end).format('h:mm a');
                this.time = start_time + ' - ' + end_time;
            }
        } else {
            this.day = "Today";
            let date = new Date();
            let endDate = new Date(date);
            endDate.setMonth(endDate.getMonth()+1);
            this.date = moment(date).format('MMMM DD, HH:MM');
            this.startTime = moment(date).format('YYYY-MM-DD HH:MM:SS');
            this.endTime = moment(endDate).format('YYYY-MM-DD HH:MM:SS');
            this.price = isNaN(localStorage.getItem('fabPostFees')) ? 0 : localStorage.getItem('fabPostFees');
        }
    },
    mounted(){
        const script = document.createElement('script');
        script.src =
      'https://www.paypal.com/sdk/js?client-id='+PAYPAL_CLIENT_ID;
        script.addEventListener('load', this.setLoaded);
        document.body.appendChild(script);
    },

    methods:{
        handlePayment(event){
            this.paymentMethod = event.target.value;
        },
        setLoaded:function(){
            window.paypal
                .Buttons({
                    createOrder: async () =>{
                        this.loading = true;
                        let orderCreated = await createOrderApi(this.price);
                        if (orderCreated.status === 'CREATED') {
                            this.loading = false;
                            this.order_id = orderCreated.id;
                            return orderCreated.id;
                        }
                    },
                    onApprove: async () => {
                        this.loading = true;
                        const order = await captureOrder(this.order_id);
                        if (order.status){
                            this.loading = false;
                            if (order.status){
                                let paymentResponse = order.data;
                                let paypal_order_id = this.order_id;
                                let paypal_capture_id = paymentResponse.purchase_units[0].payments.captures[0].id;
                                let paypal_status = paymentResponse.status;
                                if (this.type === 'video'){
                                    this.loading = true;
                                    let create = await createBooking(this.slotId, this.fabId, paymentResponse,paypal_order_id, paypal_capture_id, paypal_status,this.invite_id);
                                    if (create.status === true){
                                        this.loading = false;
                                        this.next('2');
                                    } else {
                                        // window.location.href = `/payment-error`;
                                        this.$router.push('/payment-error');
                                    }
                                } else {
                                    this.loading = true;
                                    let buy = await buyExclusivePost(this.fabId, this.startTime, this.endTime, this.price, paymentResponse,paypal_order_id, paypal_capture_id, paypal_status);
                                    if (buy.status === true){
                                        this.loading = false;
                                        this.next('2');
                                    } else {
                                        this.$router.push('/payment-error');
                                    }
                                }
                            }
                        }
                    },
                }).render(this.$refs.paypal);
        }
    }
};
</script>
