<template>
  <b-container class="py-md-4">
    <LoadingSpinner v-if="loading" />
    <div class="register_wrapper my-md-5 my-4 p-3'">
      <b-row class="justify-content-center">
        <b-col
          xl="10"
          lg="10"
        >
          <ul class="progressbar mt-5">
            <li
              :class="step === '1' || step==='2' || step === '3' ? 'active' : ''"
            >
              {{ type === "content" ? 'Exclusive access' : 'Booking videocall' }}
            </li>
            <li
              :class="step==='2' || step === '3' ? 'active' : ''"
            >
              Payment
            </li>
            <li
              :class="step==='2' || step === '3' ? 'active' : ''"
            >
              Confirmation
            </li>
          </ul>
          <Step1
            v-if="step === '0'"
            :next="handleSteps"
            :type="type"
            :slotData="slotData"
            :fabId="fabId"
            :slots="slots"
          />
          <Step2
            v-if="step === '1'"
            :next="handleSteps"
            :type="type"
            :slotData="slotData"
            :fabId="fabId"
            :slots="slots"
            :inviteId="inviteId"
          />
          <Step3
            v-if="step === '2'"
            :next="handleSteps"
            :type="type"
            :slots="slots"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { getSlotDetails } from '../../api/meeting';
import LoadingSpinner from '../Common/LoadingSpinner';
export default {
    name:'BookMeeting',
    components:{ Step1, Step2, Step3, LoadingSpinner },
    data(){
        return {
            step: '0',
            type:this.$route.params.type,
            slotData: {},
            fabId:"",
            username: localStorage.getItem('fabUsername'),
            slots: {},
            inviteId: "",
            loading: true
        };
    },
    async created(){
        this.loading = true;
        localStorage.setItem('bookType', this.$route.params.type);
        if (this.$route.params.type === "video" ){
            let solt_id = window.atob(this.$route.query.slotId);
            this.inviteId = this.$route.query.invitationId!== undefined ? window.atob(this.$route.query.invitationId) : "";
            let details = await getSlotDetails(solt_id);
            localStorage.setItem('callType', details.call_type);
            if (Object.keys(details).length > 0){
                this.slots = details;
                this.loading = false;
            } else {
                this.loading = false;
            }
        } else if (this.$route.params.type === "content"){
            this.step = "0";
            this.loading = false;
        } else {
            this.loading = false;
            this.$router.push(`/feed/${this.username}`);
        }
    },
    methods:{
        handleSteps(step){
            this.step = step;
        }
    }
};
</script>