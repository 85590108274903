<template>
  <b-container>
    <b-row class="mt-5">
      <b-col
        xl="12"
        lg="12"
      >
        <div class="text-center">
          <img
            :src="profilePic"
            :class="style['profile-image']"
            @error="setPlaceholderProfileImg"
          >
          <p class="h2 font-weight-bold mt-4 mb-0">
            Congratulations
          </p>
          <p class="h2 font-weight-bold mb-0">
            {{ type === 'content' ? `You have access to ${fabName}'s` : callType === "video" ? "You booked a videocall" : 'You booked a stream' }}
          </p>
          <p class="h2 font-weight-bold mb-0">
            {{ type === "content" ? 'exclusive contents' : `with ${fabName}!` }}
          </p>
        </div>
        <div class="my-5 text-center">
          <b-button
            v-if="type==='content'"
            class="px-5"
            pill
            variant="primary"
            @click="()=>$router.push(`/feed/${fabUsername}`)"
          >
            VISIT POPZ PROFILE
          </b-button>
          <b-button
            v-else
            class="px-5"
            pill
            variant="primary"
            @click="()=>$router.push(`/guide/${callType}`)"
          >
            READ THE GUIDE
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import style from '../../styles/bookings.module.scss';
import { setPlaceholderProfileImg } from '../../js/common-function';
export default {
    name:'Step3',
    props:{
        next:{
            type: Function,
            default:() => {}
        },
        // type:{
        //     type:String,
        //     default:""
        // },
        slots:{
            type:[Object, Array],
            default:()=>{},
        }
    },
    data(){
        return {
            style,
            profilePic: localStorage.getItem('fabProfilePic'),
            fabUsername: localStorage.getItem('fabUsername'),
            fabName: localStorage.getItem('fabName'),
            type: localStorage.getItem('bookType'),
            callType: "",
            setPlaceholderProfileImg
        };
    },
    watch:{
        // slots:function(newVal){
        //     this.callType = newVal.call_type;
        // }
    },
    created(){
        if (localStorage.getItem('callType') == 1){
            this.callType = "video";
        } else {
            this.callType = "stream";
        }
    }
};
</script>